import React from 'react'
import {
  map, compact, includes, cloneDeep
} from 'lodash'
import FullscreenTable from './FullscreenTable'
import { stringify as stringifyQuery } from '../utils/querystring'

const { helpers } = window

const COLUMNS = compact([
  {
    data: 'pipe.pipe_ref',
    title: 'ID',
    render(data, type, row) {
      if (type === 'display') {
        return `<a target="_blank" href="pipes/${row.pipe.id}">${data}</a>`
      }

      return data
    },
    defaultContent: ''
  },
  helpers.dt.data({
    data: 'pipe.updated_at',
    title: 'Last Update',
    small: true,
    type: 'date',
    orderSequence: ['desc', 'asc'],
    defaultContent: ''
  }),
  {
    data: 'pipe.latest_log_entry_creator_name',
    title: 'Updated By',
    render(data, type) {
      if (type === 'display') {
        const initials = (data || '')
          .split(',')
          .map((x) => { return (x || '').trim()[0] || '' })
          .filter((x) => { return x })
          .join('')
        return `<span title='${data}'>${initials}</span>`
      }

      return data
    },
    defaultContent: ''
  },
  {
    data: 'pipe.editor_status',
    title: 'Status',
    render(data) {
      if (typeof data === 'undefined' || data === null || data === '') {
        return 'No changes'
      }
      return data
    },
    defaultContent: ''
  },
  helpers.dt.data({
    data: 'pipe.issues',
    title: '# Issues',
    type: 'list-length',
    defaultContent: '',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.issues',
    title: 'Issues',
    type: 'list',
    small: true,
    orderable: false,
    defaultContent: '',
    showInitial: true,
    className: 'datatable-longline'
  }),
  helpers.dt.data({
    data: 'pipe.pipe_type',
    title: 'Pipe Type',
    defaultContent: '',
    showInitial: true,
  }),
  helpers.dt.data({
    data: 'pipe.installed_on',
    title: 'Date of<br>Installation',
    type: 'date',
    format: 'YYYY/MM/DD',
    defaultContent: '',
    showInitial: true,
  }),
  helpers.dt.data({
    data: 'pipe.yoaq',
    title: 'Year of<br>Acquisition',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.material_ref',
    title: 'Material',
    defaultContent: '',
    showInitial: true,
  }),
  helpers.dt.data({
    data: 'pipe.length',
    title: 'Length',
    type: 'two-decimals',
    defaultContent: '',
    showInitial: true,
  }),
  helpers.dt.data({
    data: 'pipe.diameter',
    title: 'Diameter',
    type: 'two-decimals',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.location_ref',
    title: 'Location',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.life_status',
    title: 'Life<br>Status',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.abandoned_on',
    title: 'Date of<br>Abandonment',
    type: 'date',
    format: 'YYYY/MM/DD',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.cof',
    title: 'COF',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.cost',
    title: 'COST',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_1',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_2',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_3',
    defaultContent: '',
    showInitial: true
  }),

  helpers.dt.data({
    data: 'pipe.custom_4',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_5',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_6',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_7',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.custom_8',
    defaultContent: '',
    showInitial: true
  }),
  helpers.dt.data({
    data: 'pipe.comment',
    title: 'Comment',
    defaultContent: ''
  })
])

export default class PipesTable extends React.PureComponent {
  constructor(props) {
    super(props)

    const { customFields, allowedOrdering } = this.props

    let columns = COLUMNS

    // add / remove custom fields
    columns = map(columns, (column) => {
      if ((/^pipe.custom_/).test(column.data)) {
        const key = column.data.split('.')[1]

        if (customFields[key]) {
          return { ...column, title: customFields[key] }
        }

        return null
      }

      return column
    })

    // remove empty
    columns = compact(columns)

    // add indices
    columns = map(columns, (column, index) => ({
      ...column,
      name: column.name || column.data,
      index,
      showInitial: true,
      orderable: (
        column.orderable !== undefined ? column.orderable : includes(allowedOrdering, column.data)
      )
    }))

    this.columns = columns

    this.state = {
      breaksQuery: ''
    }
  }

  onQueryChanged = (query) => {
    // TODO: for back links from map/edit
    // show be deprecated
    const queryWithStringifiedPredicate = {
      ...query,
      predicates: query.predicates ? JSON.stringify(query.predicates) : undefined
    }
    window.localStorage.setObject('PipeListScope', queryWithStringifiedPredicate)

    const breaksQuery = cloneDeep(query)
    delete breaksQuery.sort
    if (/^(Filter|Issue)_/.test(breaksQuery.scope)) {
      delete breaksQuery.scope
    }

    this.setState({ breaksQuery: stringifyQuery(breaksQuery) })
  }

  render() {
    const title = (
        <h2>Pipes</h2>
    )

    return (
      <FullscreenTable
        { ...this.props }
        title={ title }
        columns={ this.columns }
        mapUrl="./pipes/map"
        csvUrl="./pipes.csv"
        xlsxUrl="./pipes.xlsx"
        dataUrl="./pipes.json"
        onQueryChanged={ this.onQueryChanged }
        defaultOrder={ ['pipe.updated_at', 'desc'] }
      />
    )
  }
}
